<script setup lang="ts">
import { defaultWindow } from "@vueuse/core";

/**
 * Get the height of header,
 * watch scrolling state and set header to sticky calcoulating the height and all
 */
const header = ref();
const { width, height } = useElementSize(header);
const { x, y } = useWindowScroll();
const isScrollChange = computed(() => {
  if (y.value > Math.floor(height.value / 2)) {
    useNuxtApp().$utilities.isScroll.value = true;
    return true;
  }
  useNuxtApp().$utilities.isScroll.value = false;
  return false;
});

const el = ref<HTMLElement | null>(null);
// const {directions} = useScroll(el)
const scroll_state = computed(() => useScroll(el));

function scrollUp() {
  defaultWindow?.scroll({ top: 0, behavior: "smooth" });
}

const showToTop = ref(false);

watchDeep(y, () => {
  const { height } = useWindowSize()
  if (y.value > Math.floor(height.value / 3)) {
    showToTop.value = true;
  } else {
    showToTop.value = false;
  }
});

onMounted(() => {
  useNuxtApp().$general.headerHeight.value = height.value;
});
watch(height, () => {
  useNuxtApp().$general.headerHeight.value = height.value;
});
</script>

<template>
  <div class="relative max-w-[100vw] min-h-screen flex flex-col">
    <header :class="isScrollChange
      ? 'bg-gray-800/40 text-gray-50'
      : 'dark:bg-gray-800/50 bg-transparent'
      " class="sticky inset-0 z-10 backdrop-blur py-5">
      <TemplatesHeaderBarV1 ref="header" />
    </header>

    <div class="flex-grow" :style="`min-height:calc(100vh - ${height}px)`">
      <slot />
    </div>

    <footer>
      <TemplatesFooterDesktop />
    </footer>
    <UButton @click="scrollUp" v-show="showToTop"
      class="z-[2] fixed bottom-2 md:bottom-10  right-2 md:right-3 lg:right-4" variant="outline" color="primary"
      size="xs" icon="i-heroicons-arrow-up" />
  </div>
</template>
