<script setup lang="ts">
const isOpen = useNuxtApp().$utilities.modal.mobile_slideOver;
</script>

<template>
  <div class="">
    <UIcon name="i-heroicons-bars-3-bottom-left-20-solid" class="w-5 h-5 cursor-pointer" @click="isOpen = !isOpen" />
    <USlideover v-model="isOpen">
      <UCard as="div" :ui="{
      divide: '',
      base: 'overflow-hidden flex flex-col',
      background: '',
      body: {
        base: 'flex-grow',
      },
    }">
        <template #header>
          <div class="w-full relative flex flex-col items-center justify-center">
            <UIcon name="i-heroicons-chevron-left-20-solid" class="w-8 h-8 absolute left-0 cursor-pointer"
              @click="isOpen = false" />
            <span class="md:hidden">
              <TemplatesHeaderIconsDarkMode />
            </span>
          </div>
        </template>
        <MenuItemsHeaderV2 @menu-clicked="() => isOpen = false" orientation="vertical" />
      </UCard>
    </USlideover>
  </div>
</template>
