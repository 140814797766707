<script setup lang="ts">
const nuxtApp = useNuxtApp();
const isOpen = nuxtApp.$utilities.modal.login_registerModal;

const _customerStore = storeToRefs(useCustomerStore());

const customer = _customerStore.customer;

// const items = computed(() => {
const items = ref([
  [
    {
      label: customer.value.email,
      slot: "account",
      disabled: true,
    },
  ],

  [
    {
      avatar: {
        src: customer.value.metadata?.avatar_url ?? "",
        alt: customer.value.first_name + " " + customer.value.last_name,
      },
      label: "Profile",
      to: "/account",
      slot: "profile",
    },
    {
      label: "Favourite",
      to: "/account/favourite",
      icon: "i-ph-heart",
    },
    {
      label: "Wishlist",
      to: "/account/wishlist",
      icon: "i-ph-bookmark-simple",
    },
  ],
  [
    // {
    //   label: "Notification",
    //   to: "/account?id=notification",
    //   icon: "i-ph-bell",
    // },
    {
      label: "Orders",
      to: "/account/orders",
      icon: "i-ph-list-checks",
    },
  ],
  [
    {
      label: "Logout",
      icon: "i-ph-sign-out",
      click: async () => {
        const res = await useAuthStore().logout();
        if (res.status) {
          useToastNotification("Logged Out", "See you soon").default_toast(
            3000,
          );
          navigateTo("/");
        } else {
          useToastNotification().error(3000);
        }
      },
    },
  ],
]);
// });
const avatar_key = ref(Date.now());

const show = ref(false);

// const isLoggedIn = isCustomerLoggedIn()

const background = ref(
  useBackgroundColor(
    customer.value.first_name + " " + customer.value.last_name,
  ),
);

watch(customer, () => {
  background.value;
});

const key_ = ref(Date.now())
watch(useNuxtApp().$theme.isDark, () => {
  background.value
  key_.value = Date.now()
  avatar_key.value = Date.now()
})
</script>

<template>
  <div class="flex">
    <!-- <ClientOnly> -->
    <span v-if="_customerStore.isLoggedIn.value" class="flex">
      <UDropdown :key="key_" :items="items" mode="hover" :close-delay="500"
        :popper="{ placement: 'bottom-start', offsetDistance: 4 }">
        <span class="flex w-fit h-fit rounded-full" :style="background">
          <UAvatar class="ring-1 ring-gray-200 dark:ring-gray-800 shadow" :src="customer.metadata?.avatar_url ?? ''"
            :alt="`${customer.first_name} ${customer.last_name}`" size="2xs" :ui="{
      background: '',
      placeholder:
        'font-medium leading-none text-white dark:text-gray-900 truncate',
    }" :key="avatar_key" />
        </span>

        <template #account="{ item }">
          <div class="text-left">
            <p>Signed in as</p>
            <p class="truncate font-medium text-gray-900 dark:text-white">
              {{ item.label }}
            </p>
          </div>
        </template>

        <template #profile="{ item }">
          <span class="flex items-center w-fit h-fit gap-2">
            <span class="flex w-fit h-fit rounded-full" :style="background">
              <UAvatar class="ring-1 ring-gray-200 dark:ring-gray-800 shadow" v-bind="item.avatar" size="2xs" :ui="{
      background: '',
      placeholder:
        'font-medium leading-none text-white dark:text-gray-900 truncate',
    }" />
            </span>
            <span class="truncate">{{ item.label }}</span>
          </span>
        </template>
      </UDropdown>
    </span>

    <span v-else class="w-fit h-fit flex items-center">
      <UIcon @click="() => {
      navigateTo('/auth');
    }
      " name="i-ph-user" class="w-5 h-5 cursor-pointer" />
    </span>


    <!-- </ClientOnly> -->
  </div>
</template>
