<script setup lang="ts">
const { isLoggedIn } = storeToRefs(useCustomerStore())
</script>

<template>
  <UContainer class="flex items-center justify-between relative py-4 lg:py-0">
    <MenuItemsHeaderV2 class="hidden lg:flex" />
    <ClientOnly>
      <MenuItemsHeaderMobile class="lg:hidden" />
      <template #fallback>
        <UIcon name="i-heroicons-bars-3-bottom-left-20-solid" class="w-5 h-5" />
      </template>
    </ClientOnly>
    <!-- <LayoutsHeaderMobile class="lg:hidden" /> -->
    <LogoName class="lg:absolute lg:left-1/2" />

    <div class="flex items-center gap-2 md:gap-3 lg:gap-4">
      <!-- <span class="hidden md:inline-block">
                <TemplatesHeaderIconsDarkMode />
            </span> -->
      <ClientOnly>
        <TemplatesHeaderIconsDarkMode />
        <template #fallback>
          <span class="w-8 h-4"></span>
        </template>
      </ClientOnly>
      <TemplatesHeaderIconsSearch />
      <TemplatesHeaderIconsShoppingBag v-if="isLoggedIn" class="hidden md:inline-block" />
      <ClientOnly>
        <TemplatesHeaderIconsUser />
        <template #fallback>
          <UIcon name="i-ph-user" class="w-5 h-5" />
        </template>
      </ClientOnly>
      <!-- <LayoutsIconsUser class="hidden md:inline-block"/> -->
    </div>
  </UContainer>
</template>
