<script setup lang='ts'>
import type { ProductCategory } from "@medusajs/medusa";
import { defaultWindow } from "@vueuse/core";



const props = defineProps({
  label: {
    type: String,
    default: 'Shop'
  },
  to: {
    type: String,
    default: '/shop'
  },
})

const shopStore = useShopStore()
const { selectedCategories: shopSelectedCategories } = storeToRefs(shopStore)

const open = ref(false)
const categoryStore = useFilterStore()
const { best_sellers, getChildren, noParent } = storeToRefs(categoryStore)

const selectedCategory = ref(noParent.value[0])

onMounted(() => {
  selectedCategory.value = noParent.value[0]
})

function entered(data: ProductCategory) {

  selectedCategory.value = data
}


const ui = {
  active: 'text-gray-900 dark:text-white before:bg-gray-100 dark:before:bg-gray-950',
  inactive: 'text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white hover:before:bg-gray-50 dark:hover:before:bg-gray-800/50',
  label: 'truncate relative font-medium text-sm',
  base: 'relative group flex items-center py-1.5 px-3 focus:outline-none before:absolute before:inset-px before:rounded-md cursor-pointer before:w-full before:h-full'
}

const hoveredSubCategory = ref('')

// not hovered
watch(open, () => {

  if (!open.value) {
    selectedCategory.value = noParent.value[0]
  }

})


async function categoriesClickedFunc(id: string) {
  shopStore.$reset()
  shopSelectedCategories.value = [id]
  open.value = false
  if (useRoute().path == '/shop') {
    await shopStore.getProducts()
    defaultWindow?.scroll({ top: 0, behavior: 'smooth' })
  } else {
    navigateTo('/shop')
  }
  // shopStore.getProducts()
}
</script>

<template>
  <div id="shoplink">
    <UPopover v-model:open="open" mode="hover" :close-delay="300" :popper="{ offsetDistance: 20 }">
      <NuxtLink :to="to" class="group-hover:text-primary relative flex gap-1 items-center w-fit">
        <span>{{ label }}</span>
        <UIcon name="i-heroicons-chevron-down-20-solid" class="w-4 h-4" />
      </NuxtLink>

      <template #panel="{ close }">
        <div
          class="py-4 min-w-[750px] h-[500px] bg-white/80 dark:bg-gray-800/70 backdrop-blur grid grid-cols-5 text-sm uppercase font-medium shadow-md text-start">
          <div class="px-4 h-full overflow-y-auto border-r border-gray-300 dark:border-gray-700">
            <p class="text-primary-950 dark:text-primary-50 my-3">Categories</p>
            <div class="flex flex-col gap-2.5">

              <span @click="() => categoriesClickedFunc(category.id)" v-for="category of noParent"
                @mouseenter="() => entered(category as any)"
                :class="[ui.base, selectedCategory?.id == category?.id ? ui.active : ui.inactive]" class="inline-block">
                <span :class="[ui.label,]">{{ category.name
                  }}</span>
              </span>
            </div>
          </div>

          <div class="px-4 h-full overflow-y-auto no-scrollbar border-0 border-gray-300 dark:border-gray-700">
            <p class="text-primary-950 dark:text-primary-50 my-3">Sub Categories</p>
            <div class="flex flex-col gap-2.5 max-h-full">
              <span @click="() => categoriesClickedFunc(sub_category.id)"
                @mouseenter="() => hoveredSubCategory = sub_category.id"
                v-for="sub_category of getChildren(selectedCategory)"
                :class="[ui.base, hoveredSubCategory == sub_category?.id ? ui.active : ui.inactive]"
                class="inline-block">
                <span :class="[ui.label]">{{ sub_category.name }}</span>
              </span>
            </div>
          </div>

          <div v-if="best_sellers.length" class="col-span-3 px-4">
            <p class="text-primary-950 dark:text-primary-50 my-3">Best Sellers</p>
            <ProductCarouselV2 @product-clicked="close" :items="best_sellers" autoplay :time-interval="5000" />
          </div>
        </div>
      </template>
    </UPopover>
  </div>
</template>
