<template>
    <ULink to="/shopping-bag" class="relative">
        <svg :class="[width, height]" class="cyIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="28"
            viewBox="0 0 24 28" fill="none">
            <path
                d="M8.40002 7.58301H15.6C19 7.58301 19.34 9.43801 19.57 11.7013L20.47 20.4513C20.76 23.3213 20 25.6663 16.5 25.6663H7.51003C4.00003 25.6663 3.24002 23.3213 3.54002 20.4513L4.44003 11.7013C4.66003 9.43801 5.00002 7.58301 8.40002 7.58301Z"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8 9.33301V5.24967C8 3.49967 9 2.33301 10.5 2.33301H13.5C15 2.33301 16 3.49967 16 5.24967V9.33301"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20.41 19.8682H8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
        </svg>
    </ULink>
</template>

<script lang="ts" setup>
const props = defineProps({
    width: {
        type: String,
        default: "w-5",
    },
    height: {
        type: String,
        default: "h-5",
    },
});
</script>