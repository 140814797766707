<script setup lang="ts"></script>

<template>
  <div class="py-4">
    <div class="px-4 py-3 flex items-center justify-between">
      <NuxtLink to="/" class="cursor-pointer">
        <UIcon name="i-material-symbols:home" dynamic class="w-6 h-6" />
      </NuxtLink>
      <NuxtLink to="/account/favourite" class="cursor-pointer">
        <UIcon name="i-ph:heart" dynamic class="w-6 h-6" />
      </NuxtLink>
      <TemplatesFooterIconsShoppingBag width="w-5" height="w-5" />
      <!-- <NuxtLink to="/shopping-bag" class="cursor-pointer">
                <UIcon name="i-ph:shopping-cart" dynamic class="w-6 h-6" />
            </NuxtLink> -->
      <NuxtLink to="/account" class="cursor-pointer">
        <UIcon name="i-ph:user" dynamic class="w-6 h-6" />
      </NuxtLink>
    </div>
  </div>
</template>
