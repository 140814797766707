<script setup>
import { useIntervalFn } from '@vueuse/core';
const props = defineProps({
  items: {
    type: Array,
    required: true
  },
  timeInterval: {
    type: Number,
    default: 500
  },
  autoplay: Boolean,
  ui: {
    type: Object,
    default: {
      item: 'snap-start basis-full sm:basis-1/2 lg:basis-1/3 xl:basis-1/4',
      container: 'gap-5'
    }
  }
})
const carouselRef = ref()
const { pause, resume, isActive } = useIntervalFn(() => {
  if (!props.autoplay || !carouselRef.value) { return }
  // console.log('clicked');
  if (carouselRef.value.page === carouselRef.value.pages) {
    return carouselRef.value.select(0)
  }

  carouselRef.value.next()

}, props.timeInterval)

const emits = defineEmits(['productClicked'])
</script>

<template>
  <UCarousel @mouseenter="pause" @mouseleave="resume" ref="carouselRef" v-slot="{ item }" :items="items" v-bind="$attrs"
    :ui="ui">
    <slot>
      <ProductComponentV1 @click="() => emits('productClicked')" :product="item" class="w-full" />
    </slot>
  </UCarousel>
</template>
