<script setup lang='ts'>
const props = defineProps({
  orientation: {
    type: String as PropType<'horizontal' | 'vertical'>,
    default: "horizontal",
    validator: (value: string) => {
      return ["horizontal", "vertical"].includes(value);
    },
  },
  modelValue: {
    type: Boolean,
  },
});

const emits = defineEmits(['menuClicked'])

const items = [
  {
    label: "Home",
    to: '/',
    click: () => {
      emits('menuClicked')
    },
  },
  {
    label: "Shop",
    // to: '/shop'
  },
  {
    label: "Blog",
    to: '/blog',
    click: () => {
      emits('menuClicked')
    },
  },
  {
    label: "Contact",
    to: "/support/contact",
    click: () => {
      emits('menuClicked')
    },
  },
]

const shop_deepLevel = ref(1)


const { best_sellers, getChildren, noParent } = storeToRefs(useFilterStore())
const selectedCategory = ref(noParent.value[0])

function entered(data: any) {
  selectedCategory.value = data
}

const firstLevelMenu = noParent.value.map((item) => {
  return {
    label: item.name,
    data: item,
    // to: `/shop/jewels/categories/${item.handle}`,
    // click:()=>{
    //   emits('menuClicked')
    // }
  }
})

const SecondLevelMenu = computed(() => getChildren.value(selectedCategory.value).map((x) => {
  return {
    label: x.name,
    data: x,
    // to: `/shop/jewels/categories/${x.handle}`,
    // click:()=>{
    //   emits('menuClicked')
    // }
  }
}))

function firstLevelClicked(data: any) {
  entered(data)
  shop_deepLevel.value = 3
}
function secondLevelClicked(data: any) {
  // shop_deepLevel.value = 3
  entered(data)
}

const scrollState = useNuxtApp().$utilities.isScroll
</script>

<template>
  <div>
    <UHorizontalNavigation v-if="orientation == 'horizontal'" :links="items"
      :ui="{ base: 'text-sm uppercase', inactive: scrollState ? 'text-primary-100  hover:text-white' : 'text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white', active: scrollState ? 'text-white' : 'text-gray-900 dark:text-white' }">
      <template #default="{ link }">
        <span class="inline-block group" :class="[scrollState && 'text-white dark:text-white']">
          <MenuItemsHeaderMegaMenuShop v-if="link.label === 'Shop'" />
          <span v-else class="group-hover:text-primary relative">{{ link.label
            }}</span>
        </span>



      </template>
    </UHorizontalNavigation>

    <div v-else class="overflow-y-auto">
      <div v-show="shop_deepLevel === 1">
        <UVerticalNavigation :links="items">
          <template #default="{ link }">
            <div v-if="link.label === 'Shop'" class="w-full flex items-center justify-between">
              <span class="group-hover:text-primary relative">{{ link.label }}</span>

              <UIcon @click="shop_deepLevel = 2" name="i-heroicons-chevron-right-20-solid"
                class="w-5 h-5 cursor-pointer" />

            </div>

            <span v-else class="group-hover:text-primary relative">{{ link.label }}</span>

          </template>
        </UVerticalNavigation>
      </div>

      <div v-show="shop_deepLevel === 2">
        <UCard :ui="{ divide: '', background: 'bg-transparent dark:bg-transparent' }">
          <template #header>
            <UButton @click="shop_deepLevel = 1" variant="solid" color="gray"
              icon="i-heroicons-chevron-left-20-solid" />
          </template>

          <UVerticalNavigation :links="firstLevelMenu">
            <template #default="{ link }">
              <div class="w-full flex gap-5 items-center justify-between">
                <ULink :to="`/shop/jewels/categories/${link.data.handle}`" @click="() => emits('menuClicked')"
                  class="truncate relative flex-grow text-start capitalize">
                  {{ link.label }}
                </ULink>
                <UIcon @click="() => firstLevelClicked(link.data)" name="i-heroicons-chevron-right-20-solid"
                  class="w-5 h-5 cursor-pointer" />
              </div>
            </template>
          </UVerticalNavigation>
        </UCard>
      </div>

      <div v-show="shop_deepLevel === 3">
        <UCard :ui="{ divide: '', background: 'bg-transparent dark:bg-transparent' }">
          <template #header>
            <UButton @click="shop_deepLevel = 2" variant="solid" color="gray"
              icon="i-heroicons-chevron-left-20-solid" />
          </template>

          <UVerticalNavigation :links="SecondLevelMenu">
            <template #default="{ link }">
              <div class="w-full flex items-center justify-between">
                <ULink :to="`/shop/jewels/categories/${link.data.handle}`" @click="() => emits('menuClicked')"
                  class="truncate relative flex-grow text-start capitalize">{{ link.label }}
                </ULink>
                <!-- <UIcon @click="() => secondLevelClicked(link.data)" name="i-heroicons-chevron-right-20-solid"
                  class="w-5 h-5 cursor-pointer" /> -->
              </div>
            </template>
          </UVerticalNavigation>
        </UCard>
      </div>
    </div>

  </div>
</template>
