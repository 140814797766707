<script setup lang="ts">
import { defaultWindow } from "@vueuse/core";

const mobile_footer = ref();
const { width, height } = useWindowSize();

// scroll direction
const { x, y } = useWindowScroll();

const _old_y = ref(0);

const footer_becomes_sticky = ref(false);
watchDeep(y, () => {
  if (y.value > _old_y.value) {
    // // console.log('scrolling down');
    footer_becomes_sticky.value = false;
  } else {
    // // console.log('scrolling up');
    footer_becomes_sticky.value = true;
  }

  _old_y.value = y.value;
});

function scrollUp() {
  defaultWindow?.scroll({ top: 0, behavior: "smooth" });
}
</script>

<template>
  <div>
    <div class="bg-[#50F0FB]/5 dark:bg-gray-800/50 relative hidden md:block">
      <TemplatesFooterMainBarv1 />
      <TemplatesFooterBottomBarV1 />
    </div>
    <div v-show="footer_becomes_sticky" ref="mobile_footer" :class="[footer_becomes_sticky && 'bottom-0 fixed h-fit']"
      class="w-full z-[5] md:hidden bg-white dark:bg-gray-950 ring ring-transparent shadow">
      <TemplatesFooterMobileV1 />
    </div>


  </div>
</template>
