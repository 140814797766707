<script setup lang="ts">
import type { ISearchResultHits, IHit } from "~/types";
const isOpen = useNuxtApp().$utilities.modal.search

// for determining how many results should be fetched at a time
const default_limit = ref(0)
const { width } = useWindowSize()
if (width.value < 640) {
  default_limit.value = 2
} else if (width.value < 768) {
  default_limit.value = 4
} else if (width.value < 1024) {
  default_limit.value = 6
} else if (width.value < 1280) {
  default_limit.value = 8
} else {
  default_limit.value = 10
}
//-------------------------------------

const q = ref("");

const limit = computed(() => {
  if (width.value < 578) {
    return 2
  }
  else if (width.value < 640) {
    return 4
  } else if (width.value < 768) {
    return 6
  } else if (width.value < 1024) {
    return 8
  } else {
    return 10
  }
})
const offset = ref(0)
const count = ref(1)
const total = computed(() => Math.ceil(count.value / limit.value))

const reqQuery = computed(() => {
  return {
    q: q.value,
    limit: limit.value,
    offset: offset.value
  }
})

const { execute: searchFunction, pending, data: searchResults } = await useAsyncData('search_products', async () => {
  // const { hits, } = await useCybandyClient().products.search({ ...reqQuery.value })

  const { hits, length, estimatedTotalHits: nbHits } = await useCybandyClient().cybandyFetch('/store/products/search', 'post', reqQuery.value) as ISearchResultHits
  count.value = nbHits
  if (hits) {
    return hits as IHit[]
  }
  return []
}, { immediate: false, watch: [] })



function next() {
  offset.value += 1
}
function prev() {
  offset.value -= 1
}

// reset after closing modal
watch(isOpen, () => {
  if (isOpen.value) {
    q.value = ''
    // limit.value = default_limit.value
    offset.value = 0
    count.value = 1
    searchResults.value = []
  }
})

// controls fetching data
watch(reqQuery, async () => {
  if (!q.value) {
    searchResults.value = []
    return
  }
  await searchFunction()
})

// onBeforeRouteLeave(() => {
//   isOpen.value = false
// })

function viewAll() {
  isOpen.value = false
  navigateTo(`/search?q=${q.value}`)
}

</script>

<template>
  <span class="flex">
    <UIcon dynamic class="w-5 h-5 cyIco cursor-pointer" name="i-ph-magnifying-glass" @click="() => (isOpen = true)" />
    <UModal v-model="isOpen" :ui="{
      container: 'items-start sm:items-start ',
      width: 'w-full lg:w-10/12 sm:max-w-4xl'
    }">
      <UCard :ui="{ divide: '', }">
        <template #header>
          <UInput v-model="q" name="q" placeholder="Search..." icon="i-heroicons-magnifying-glass-20-solid"
            autocomplete="off" :ui="{ icon: { trailing: { pointer: '' } } }">
            <template #trailing>
              <UButton v-show="q !== ''" color="gray" variant="link" icon="i-heroicons-x-mark-20-solid" :padded="false"
                @click="q = ''" />
            </template>
          </UInput>
          <!-- <UButton @click="() => (isOpen = false)" class="fixed top-4 right-4" color="gray" variant="ghost" size="lg"
            icon="i-heroicons-x-mark-20-solid" /> -->
        </template>

        <div v-if="searchResults">
          <div v-if="searchResults.length"
            class="grid gap-5 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
            <ProductSearchV1 v-for="item of searchResults" :item="item" />
          </div>
        </div>

        <template #footer>
          <div v-if="searchResults?.length" class="flex items-center justify-between">
            <UButton @click="viewAll" label="View All" variant="outline" />
            <div class="flex items-center justify-end">
              <UIcon @click="prev" :class="offset < 1 ? 'cursor-not-allowed' : 'cursor-pointer'"
                name="i-heroicons-arrow-left" class="text-gray hover:text-primary w-4 h-4 " />

              <span class="px-4">{{ offset + 1 }} / {{ total }}</span>

              <UIcon @click="next" :class="total == 1 ? 'cursor-not-allowed' : 'cursor-pointer'"
                name="i-heroicons-arrow-right" class="text-gray  hover:text-primary w-4 h-4 " />

            </div>
          </div>
        </template>
      </UCard>
    </UModal>
  </span>
</template>

<style scoped></style>
