<script setup lang="ts">
const q = ref("");

const subFunc = async () => {
  q.value = ''
}
</script>

<template>
  <UInput v-model="q" name="email" placeholder="Email Address" padded :ui="{
    icon: { trailing: { pointer: '' } },
    trailing: {
      padding: {
        md: 'pd-10',
      },
    },
  }">
    <template #trailing>
      <UButton @click="subFunc" color="primary" icon="i-heroicons-chevron-right" size="xs" variant="soft" />
    </template>
  </UInput>
</template>
