<script setup lang="ts">

const { info: company_information } = storeToRefs(useCompanyStore());
const address = computed(() => company_information.value.address);
const social = computed(() => company_information.value.social_media);

const footer_menu = [
  {
    label: "about",
    to: "#",
    content: [
      {
        label: "Our Company",
        to: "#",
      },
      {
        label: "Our Products",
        to: "#",
      },
      {
        label: "Latest Blogs",
        to: "/blog",
      },
      {
        label: "Why us",
        to: "#",
      },
    ],
  },
  {
    label: "Support",
    to: "/support",
    content: [
      {
        label: "Terms & conditions",
        to: "/support/tnc",
      },
      {
        label: "Privacy Policy",
        to: "/support/privacy-policy",
      },
      {
        label: "Contact support",
        to: "/support/contact",
      },
      {
        label: "FAQ",
        to: "/support/faq",
      },
    ],
  },
];
</script>

<template>
  <div class="relative pt-[30px] md:pt-[60px] lg:pt-[90px] pb-[30px]">
    <div class="flex gap-5 justify-around lg:justify-between cy-container">
      <div class="basis-full sm:basis-1/2 lg:basis-1/4 grid gap-8">
        <span class="w-full grid justify-center sm:justify-start">
          <LogoName />
        </span>

        <ClientOnly>
          <div
            class="grid gap-2 text-center sm:text-start justify-center sm:justify-start capitalize text-gray-500 dark:text-gray-400">
            <!--address & co-->
            <p class="hover:text-gray-900 dark:hover:text-white">
              <ULink v-show="address?.phone" :to="`tel:${address?.phone}`">{{
                address?.phone
              }}</ULink>
            </p>
            <p class="hover:text-gray-900 dark:hover:text-white">
              {{ address?.address_1 }}
              <span v-show="address?.address_2">, {{ address?.address_2 }}</span>
            </p>
            <p class="hover:text-gray-900 dark:hover:text-white flex items-center gap-0.5">
              <span>
                {{ address?.postal_code }} <template v-if="address?.postal_code">,</template>
                {{ address?.city }}
              </span>
              <span v-if="address?.country" class="hover:text-gray-900 dark:hover:text-white">
                {{ address.country.display_name }}

              </span>
              <span v-else-if="address?.country_code" class="hover:text-gray-900 dark:hover:text-white uppercase">
                {{ address?.country_code }}

              </span>
              <UAvatar v-if="address?.country_code" size="xs" :src="`https://flagcdn.com/${address?.country_code}.svg`"
                crossorigin="anonymous" class="w-4 h-3" />
            </p>

            <!--social icons-->
            <div class="flex flex-wrap items-center justify-center sm:justify-start gap-4">
              <ULink v-show="social?.facebook" class="hover:text-gray-900 dark:hover:text-white" :to="social?.facebook"
                target="_blank">
                <UIcon class="w-6 h-6" dynamic name="i-bxl-facebook" />
              </ULink>

              <ULink v-show="social?.instagram" class="hover:text-gray-900 dark:hover:text-white"
                :to="social?.instagram" target="_blank">
                <UIcon class="w-6 h-6" dynamic name="i-mdi-instagram" />
              </ULink>

              <ULink v-show="social?.pinterest" class="hover:text-gray-900 dark:hover:text-white"
                :to="social?.pinterest" target="_blank">
                <UIcon class="w-6 h-6" dynamic name="i-bxl-pinterest" />
              </ULink>

              <ULink v-show="social?.etsy" class="hover:text-gray-900 dark:hover:text-white" :to="social?.etsy"
                target="_blank">
                <UIcon class="w-8 h-8" dynamic name="i-bxl-etsy" />
              </ULink>

              <ULink v-show="social?.shopify" class="hover:text-gray-900 dark:hover:text-white" :to="social?.shopify"
                target="_blank">
                <UIcon class="w-8 h-8" dynamic name="i-bxl-shopify" />
              </ULink>
            </div>
          </div>
        </ClientOnly>
      </div>

      <div class="hidden px-4 py-2 lg:grid lg:grid-cols-2 gap-5 lg:basis-2/4 text-gray-500 dark:text-gray-400">
        <div v-for=" menu  in  footer_menu " class="col-span-1 space-y-5">
          <ULink :to="menu.to" class="text-lg capitalize text-gray-900 dark:text-white">{{ menu.label }}</ULink>
          <div class="grid gap-2">
            <a class="capitalize group w-full" v-for=" item  in  menu.content " :href="item.to">
              <span class="group-hover:text-gray-900 group-hover:dark:text-white w-full">{{ item.label }}</span>
            </a>
          </div>
        </div>
      </div>

      <!-- <div class="bg-gray-600 h-16"></div> -->
      <div class="px-4 py-2 hidden sm:grid sm:basis-1/2 lg:basis1/4 gap-5">
        <p class="text-lg capitalize">Subscribe Newsletter</p>
        <UFormGroup description="Get notified of new arrivals"
          :help="`By subscribing to newsletter you agree with our `">
          <!-- <UtilitiesSubscriptionEmail /> -->

          <UtilitiesSubscriptionEmail />
          <template #description>
            <span>Get notified of new arrivals</span>
          </template>

          <template #help>
            <span>By subscribing to our newsletter you agree with our
              <ULink to="/support/privacy-policy" class="text-primary font-medium">Privacy Policy</ULink>
            </span>
          </template>
        </UFormGroup>
      </div>
    </div>
  </div>
</template>
